@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined'); // needed for the sidenav.
@import './_color-styles.scss';
@import './_typography.scss';
@import './_mobile-styles.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.app-container-mobile {
  zui-sidenav {
    mat-sidenav-container {
      mat-sidenav-content {
        .content-container {
          @include apply-moblie();
        }
      }
    }
  }
}

.update-settings-confirmation-dialog {
  .mat-dialog-container {
    padding: 20px;

    .mat-button-base,
    .mat-button-wrapper,
    .mat-stroked-button,
    .mat-flat-button {
      margin: 0;
      padding: 0;
    }
  }
}

#toast-container {
  &.toast-bottom-right {
    right: 24px;
    bottom: 24px;
  }

  app-notification.ngx-toastr:last-child {
    margin-bottom: 0px;
  }

  .chunk {
    line-height: 19px;
  }

  a {
    color: zonar-getColor(light-blue);
  }
}
